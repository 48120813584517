<template>
  <div class="is-fullhd mt-5 px-2">
    <article class="message is-danger" v-if="!token">
      <div class="message-body">
        You have not signed in yet. Please go back to <a href="/">home page</a> to sign in first.
      </div>
    </article>
    <div v-if="token">
      <div>
        <span class="is-pulled-right">
          <div class="dropdown is-hoverable is-right">
            <div class="dropdown-trigger">
              <button class="button" aria-haspopup="true" aria-controls="dropdown-menu4">
                <span>Export ...</span>
                <span class="icon is-small">
                  <i class="fas fa-angle-down" aria-hidden="true"></i>
                </span>
              </button>
            </div>
            <div class="dropdown-menu" id="dropdown-menu4" role="menu">
              <div class="dropdown-content">
                <a @click="exportThisPage" class="dropdown-item">Export this page</a>
                <a @click="exportMultipleDaysModal.opened = true" class="dropdown-item">Export Multiple Days</a>
                <a @click="exportPricesModal.opened = true" class="dropdown-item">Export One Day</a>
              </div>
            </div>
          </div>
        </span>

        <span class="is-pulled-right mr-3">
          <div class="dropdown is-hoverable is-right">
            <div class="dropdown-trigger">
              <button class="button" aria-haspopup="true" aria-controls="dropdown-menu4">
                <span>Upload ...</span>
                <span class="icon is-small">
                  <i class="fas fa-angle-down" aria-hidden="true"></i>
                </span>
              </button>
            </div>
            <div class="dropdown-menu" id="dropdown-menu4" role="menu">
              <div class="dropdown-content">
                <a @click="uploadWhilesaleModal.opened = true" class="dropdown-item">Upload Wholesale Prices</a>
                <a @click="uploadPromotionModal.opened = true" class="dropdown-item">Upload promotions</a>
              </div>
            </div>
          </div>
        </span>
        
        <h1 class="title">Products</h1>
      </div>
      <div class="columns mt-4">

        <div class="column field has-addons mb-0 pb-0">
          <p class="control has-icons-left">
            <span class="select">
              <select v-model="classFilter">
                <option :value="'all'">{{'All Class (' + productClasses.all + ')'}}</option>
                <option v-for="(c, i) in productClassesKeys"  :key="'product-class-option-' + i" :value="c">
                  {{'Class: ' + c + ' (' + productClasses[c] + ')'}}
                </option>
              </select>
            </span>
            <span class="icon is-small is-left">
              <i class="fas fa-filter"></i>
            </span>
          </p>
          <p class="control has-icons-left">
            <span class="select">
              <select v-model="statusFilter">
                <option :value="'all'">All{{statusFilter == 'all' ? ' (' + showingProducts.length + ')' : ''}}</option>
                <option :value="'out of stock'">Out of Stock{{statusFilter == 'out of stock' ? ' (' + showingProducts.length + ')' : ''}}</option>
                <option :value="'price hidden'">Price Hidden{{statusFilter == 'price hidden' ? ' (' + showingProducts.length + ')' : ''}}</option>
              </select>
            </span>
            <span class="icon is-small is-left">
              <i class="fas fa-filter"></i>
            </span>
          </p>
        </div>
        <div class="column field  mb-0 pb-0">
          <p class="control has-icons-left">
            <input class="input" type="text" placeholder="Search in product name, model" v-model="search">
            <span class="icon is-small is-left">
              <i class="fas fa-search"></i>
            </span>
          </p>
        </div>
      </div>
      <div class="mt-3">
        <div v-if="waiting">
          <span class="icon is-medium is-size-4">
            <i class="fas fa-spinner fa-pulse"></i>
          </span>
        </div>
        <div v-else>
          <table class="table is-fullwidth is-hoverable">
            <thead>
              <tr>
                <th class="is-clickable" @click="changeSortOption('id')">
                  <span>Id</span>
                  <span class="icon" v-if="sortOption.field == 'id'">
                    <i class="fas" :class="{'fa-sort-up': sortOption.asc, 'fa-sort-down': !sortOption.asc}"></i>
                  </span>
                </th>
                <!--<th class="is-clickable" @click="changeSortOption('name')">
                  <span>Name</span>
                  <span class="icon" v-if="sortOption.field == 'name'">
                    <i class="fas" :class="{'fa-sort-up': sortOption.asc, 'fa-sort-down': !sortOption.asc}"></i>
                  </span>
                </th>-->
                <th class="is-clickable" @click="changeSortOption('model')">
                  <span>Model</span>
                  <span class="icon" v-if="sortOption.field == 'model'">
                    <i class="fas" :class="{'fa-sort-up': sortOption.asc, 'fa-sort-down': !sortOption.asc}"></i>
                  </span>
                </th>
                <th class="is-clickable has-text-right" @click="changeSortOption('nsInventoryAmount')">
                  <span>NS Inventory <br/><span class="is-size-7 has-text-grey">CA, NY, TX</span></span>
                  <span class="icon" v-if="sortOption.field == 'nsInventoryAmount'">
                    <i class="fas" :class="{'fa-sort-up': sortOption.asc, 'fa-sort-down': !sortOption.asc}"></i>
                  </span>
                </th>
                <th class="is-clickable has-text-right" @click="changeSortOption('competitorPriceValue')">
                  <span>Competitor</span>
                  <span class="icon" v-if="sortOption.field == 'competitorPriceValue'">
                    <i class="fas" :class="{'fa-sort-up': sortOption.asc, 'fa-sort-down': !sortOption.asc}"></i>
                  </span>
                </th>
                <th class="is-clickable has-text-right" @click="changeSortOption('homeDepotWholesalePriceValue')">
                  <span>Home Depot Wholesale<br/><span class="is-size-7 has-text-grey">Promotion</span></span>
                  <span class="icon" v-if="sortOption.field == 'homeDepotWholesalePriceValue'">
                    <i class="fas" :class="{'fa-sort-up': sortOption.asc, 'fa-sort-down': !sortOption.asc}"></i>
                  </span>
                </th>
                <th class="is-clickable has-text-right" @click="changeSortOption('homeDepotPriceValue')">
                  <span>Home Depot Price</span>
                  <span class="icon" v-if="sortOption.field == 'homeDepotPriceValue'">
                    <i class="fas" :class="{'fa-sort-up': sortOption.asc, 'fa-sort-down': !sortOption.asc}"></i>
                  </span>
                </th>
                <td class="is-clickable has-text-right" @click="changeSortOption('homeDepotPriceLatestChange')">
                  <span>Change From</span>
                  <span class="icon" v-if="sortOption.field == 'homeDepotPriceLatestChange'">
                    <i class="fas" :class="{'fa-sort-up': sortOption.asc, 'fa-sort-down': !sortOption.asc}"></i>
                  </span>
                </td>
                <th class="is-clickable has-text-right" @click="changeSortOption('wayfairWholesalePriceValue')">
                  <span>Wayfair Wholesale<br/><span class="is-size-7 has-text-grey">Promotion</span></span>
                  <span class="icon" v-if="sortOption.field == 'wayfairWholesalePriceValue'">
                    <i class="fas" :class="{'fa-sort-up': sortOption.asc, 'fa-sort-down': !sortOption.asc}"></i>
                  </span>
                </th>
                <th class="is-clickable has-text-right" @click="changeSortOption('wayfairPriceValue')">
                  <span>Wayfair Price</span>
                  <span class="icon" v-if="sortOption.field == 'wayfairPriceValue'">
                    <i class="fas" :class="{'fa-sort-up': sortOption.asc, 'fa-sort-down': !sortOption.asc}"></i>
                  </span>
                </th>
                <td class="is-clickable has-text-right" @click="changeSortOption('wayfairPriceLatestChange')">
                  <span>Change From</span>
                  <span class="icon" v-if="sortOption.field == 'wayfairPriceLatestChange'">
                    <i class="fas" :class="{'fa-sort-up': sortOption.asc, 'fa-sort-down': !sortOption.asc}"></i>
                  </span>
                </td>
                <th class="is-clickable has-text-right" @click="changeSortOption('overstockPriceValue')">
                  <span>Overstock</span>
                  <span class="icon" v-if="sortOption.field == 'overstockPriceValue'">
                    <i class="fas" :class="{'fa-sort-up': sortOption.asc, 'fa-sort-down': !sortOption.asc}"></i>
                  </span>
                </th>
                <td class="is-clickable has-text-right" @click="changeSortOption('overstockPriceLatestChange')">
                  <span>Change From</span>
                  <span class="icon" v-if="sortOption.field == 'overstockPriceLatestChange'">
                    <i class="fas" :class="{'fa-sort-up': sortOption.asc, 'fa-sort-down': !sortOption.asc}"></i>
                  </span>
                </td>
                <th class="is-clickable has-text-right" @click="changeSortOption('amazonPriceValue')">
                  <span>Amazon</span>
                  <span class="icon" v-if="sortOption.field == 'amazonPriceValue'">
                    <i class="fas" :class="{'fa-sort-up': sortOption.asc, 'fa-sort-down': !sortOption.asc}"></i>
                  </span>
                </th>
                <td class="is-clickable has-text-right" @click="changeSortOption('amazonPriceLatestChange')">
                  <span>Change From</span>
                  <span class="icon" v-if="sortOption.field == 'amazonPriceLatestChange'">
                    <i class="fas" :class="{'fa-sort-up': sortOption.asc, 'fa-sort-down': !sortOption.asc}"></i>
                  </span>
                </td>
                <th class="is-clickable has-text-right" @click="changeSortOption('lowesPriceValue')">
                  <span>Lowes</span>
                  <span class="icon" v-if="sortOption.field == 'lowesPriceValue'">
                    <i class="fas" :class="{'fa-sort-up': sortOption.asc, 'fa-sort-down': !sortOption.asc}"></i>
                  </span>
                </th>
                <td class="is-clickable has-text-right" @click="changeSortOption('lowesPriceLatestChange')">
                  <span>Change From</span>
                  <span class="icon" v-if="sortOption.field == 'lowesPriceLatestChange'">
                    <i class="fas" :class="{'fa-sort-up': sortOption.asc, 'fa-sort-down': !sortOption.asc}"></i>
                  </span>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(p, i) in showingProducts" :key="'product-' + i" v-show="!p.isChild || parentMap[p.parentId].opened" :class="{'is-parent': p.isParent, 'is-child': p.isChild}">
                <td>{{p.id}}</td>
                <!--<td>
                  <abbr v-if="p.nameAbbr" :title="p.name">{{p.nameAbbr}}</abbr>
                  <span v-if="!p.nameAbbr">{{p.name}}</span>
                </td>-->
                <td>
                  <span v-if="p.isParent" @click="parentMap[p.id].opened = !parentMap[p.id].opened" class="is-clickable">
                    <abbr :title="p.nameAbbr">{{p.model}}</abbr>
                  </span>
                  <router-link :to="'/product/' + p.id" v-else>
                    <abbr :title="p.nameAbbr">{{p.model}}</abbr>
                  </router-link>
                </td>
                <td class="has-text-right">
                  <span>{{p.nsInventoryAmount}}</span><br/>
                  <span class="is-size-7 has-text-grey" v-if="p.nsInventoryAmount && (p.nsCaInventoryAmount || p.nsNyInventoryAmount || p.nsTxInventoryAmount) ">
                    <span>{{p.nsCaInventoryAmount + ', ' + p.nsNyInventoryAmount + ', ' + p.nsTxInventoryAmount }}</span>
                  </span>
                </td>
                <td class="has-text-right price-cell">
                  <span v-if="p.competitorPriceDate"><i>{{p.competitorPriceLabel}}</i></span><br/>
                  <span v-if="p.competitorPriceBrand"><i>{{p.competitorPriceBrand}}</i></span><br/>
                  <span v-if="p.competitorPriceDate" class="is-size-7 has-text-grey">{{p.competitorPriceDate}}</span>
                </td>
                <td class="has-text-right">
                  <span>{{p.homeDepotWholesalePriceLabel}}</span><br/>
                  <span class="is-size-7 has-text-grey">
                    <span>{{p.homeDepotPromotionPercentageLabel}}</span>
                  </span>
                </td>
                <td class="has-text-right price-cell">
                  <span v-if="p.homeDepotPriceFlag && p.homeDepotPriceFlag.includes('Out of Stock')"><span class="tag is-danger">oos</span>&nbsp;</span>
                  <span>{{p.homeDepotPriceLabel}}</span><br/>
                  <span class="is-size-7 has-text-grey">{{p.homeDepotPriceDate}}</span>
                </td>
                <td class="has-text-right price-change-cell">
                  <span v-if="p.homeDepotPriceLatestChange">
                    <span>{{p.homeDepotPriceLatestChangePriceLabel}}</span>
                    <span v-if="p.homeDepotPriceLatestChangeLabels[1]" class="icon is-size-6" :class="{'has-text-success': p.homeDepotPriceLatestChangeLabels[1] == '-', 'has-text-danger': p.homeDepotPriceLatestChangeLabels[1] == '+'}">
                      <i class="fas" :class="{'fa-arrow-up': p.homeDepotPriceLatestChangeLabels[1] == '+', 'fa-arrow-down': p.homeDepotPriceLatestChangeLabels[1] == '-'}"></i>
                    </span><br/>
                    <span class="is-size-7 has-text-grey">{{p.homeDepotPriceLatestChangeLabels[0]}}</span>
                  </span>
                </td>
                <td class="has-text-right">
                  <span>{{p.wayfairWholesalePriceLabel}}</span><br/>
                  <span class="is-size-7 has-text-grey">
                    <span>{{p.wayfairPromotionPercentageLabel}}</span>
                  </span>
                </td>
                <td class="has-text-right price-cell">
                  <span v-if="p.wayfairPriceFlag && p.wayfairPriceFlag.includes('Out of Stock')"><span class="tag is-danger" >oos</span>&nbsp;</span>
                  <span>{{p.wayfairPriceLabel}}</span><br/>
                  <span class="is-size-7 has-text-grey">{{p.wayfairPriceDate}}</span>
                </td>
                <td class="has-text-right price-change-cell">
                  <span v-if="p.wayfairPriceLatestChange">
                    <span>{{p.wayfairPriceLatestChangePriceLabel}}</span>
                    <span v-if="p.wayfairPriceLatestChangeLabels[1]" class="icon is-size-6" :class="{'has-text-success': p.wayfairPriceLatestChangeLabels[1] == '-', 'has-text-danger': p.wayfairPriceLatestChangeLabels[1] == '+'}">
                      <i class="fas" :class="{'fa-arrow-up': p.wayfairPriceLatestChangeLabels[1] == '+', 'fa-arrow-down': p.wayfairPriceLatestChangeLabels[1] == '-'}"></i>
                    </span><br/>
                    <span class="is-size-7 has-text-grey">{{p.wayfairPriceLatestChangeLabels[0]}}</span>
                  </span>
                </td>
                <td class="has-text-right price-cell">
                  <span  v-if="p.overstockPriceFlag && p.overstockPriceFlag.includes('Out of Stock')"><span class="tag is-danger">oos</span>&nbsp;</span>
                  <span>{{p.overstockPriceLabel}}</span><br/>
                  <span class="is-size-7 has-text-grey">{{p.overstockPriceDate}}</span>
                </td>
                <td class="has-text-right price-change-cell">
                  <span v-if="p.overstockPriceLatestChange">
                    <span>{{p.overstockPriceLatestChangePriceLabel}}</span>
                    <span v-if="p.overstockPriceLatestChangeLabels[1]" class="icon is-size-6" :class="{'has-text-success': p.overstockPriceLatestChangeLabels[1] == '-', 'has-text-danger': p.overstockPriceLatestChangeLabels[1] == '+'}">
                      <i class="fas" :class="{'fa-arrow-up': p.overstockPriceLatestChangeLabels[1] == '+', 'fa-arrow-down': p.overstockPriceLatestChangeLabels[1] == '-'}"></i>
                    </span><br/>
                    <span class="is-size-7 has-text-grey">{{p.overstockPriceLatestChangeLabels[0]}}</span>
                  </span>
                </td>
                <td class="has-text-right price-cell">
                  <span v-if="p.amazonPriceFlag && p.amazonPriceFlag.includes('Out of Stock')"><span class="tag is-danger">oos</span>&nbsp;</span>
                  <span v-if="p.amazonPriceFlag && p.amazonPriceFlag.includes('Price Hidden')"><span class="tag is-warning">hidden</span>&nbsp;</span>
                  <span>{{p.amazonPriceLabel}}</span><br/>
                  <span class="is-size-7 has-text-grey">{{p.amazonPriceDate}}</span>
                </td>
                <td class="has-text-right price-change-cell">
                  <span v-if="p.amazonPriceLatestChange">
                    <span>{{p.amazonPriceLatestChangePriceLabel}}</span>
                    <span v-if="p.amazonPriceLatestChangeLabels[1]" class="icon is-size-6" :class="{'has-text-success': p.amazonPriceLatestChangeLabels[1] == '-', 'has-text-danger': p.amazonPriceLatestChangeLabels[1] == '+'}">
                      <i class="fas" :class="{'fa-arrow-up': p.amazonPriceLatestChangeLabels[1] == '+', 'fa-arrow-down': p.amazonPriceLatestChangeLabels[1] == '-'}"></i>
                    </span><br/>
                    <span class="is-size-7 has-text-grey">{{p.amazonPriceLatestChangeLabels[0]}}</span>
                  </span>
                </td>
                <td class="has-text-right price-cell">
                  <span v-if="p.lowesPriceFlag && p.lowesPriceFlag.includes('Out of Stock')"><span class="tag is-danger">oos</span>&nbsp;</span>
                  <span>{{p.lowesPriceLabel}}</span><br/>
                  <span class="is-size-7 has-text-grey">{{p.lowesPriceDate}}</span>
                </td>
                <td class="has-text-right price-change-cell">
                  <span v-if="p.lowesPriceLatestChange">
                    <span>{{p.lowesPriceLatestChangePriceLabel}}</span>
                    <span v-if="p.lowesPriceLatestChangeLabels[1]" class="icon is-size-6" :class="{'has-text-success': p.lowesPriceLatestChangeLabels[1] == '-', 'has-text-danger': p.lowesPriceLatestChangeLabels[1] == '+'}">
                      <i class="fas" :class="{'fa-arrow-up': p.lowesPriceLatestChangeLabels[1] == '+', 'fa-arrow-down': p.lowesPriceLatestChangeLabels[1] == '-'}"></i>
                    </span><br/>
                    <span class="is-size-7 has-text-grey">{{p.lowesPriceLatestChangeLabels[0]}}</span>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div v-if="error" class="notification is-danger is-light">
        <button class="delete" @click="error=''"></button>
        {{error}}
      </div>

      <export-prices-modal :opened="exportPricesModal.opened" @closeExportPricesModal="exportPricesModal.opened=false"></export-prices-modal>
      <export-multiple-days-modal :opened="exportMultipleDaysModal.opened" :showingProducts="showingProducts" @closeExportPricesModal="exportMultipleDaysModal.opened=false"></export-multiple-days-modal>
      <upload-wholesale-modal :opened="uploadWhilesaleModal.opened" @closeUploadWholesaleModal="uploadWhilesaleModalClosed"></upload-wholesale-modal>
      <upload-promotion-modal :opened="uploadPromotionModal.opened" @closeUploadPromotionModal="uploadPromotionModalClosed"></upload-promotion-modal>
    </div>
  </div>
</template>

<script>
import ExportPricesModal from '@/components/modals/ExportPricesModal'
import ExportMultipleDaysModal from '@/components/modals/ExportMultipleDaysModal'
import UploadWholesaleModal from '@/components/modals/UploadWholesaleModal'
import UploadPromotionModal from '@/components/modals/UploadPromotionModal'
import { saveAs } from 'file-saver'

function formatPrice (value) {
  if (!value) {
    return ''
  }
  return '$' + value.toLocaleString('en-US', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    useGrouping: false
  })
}

export default {
  name: 'Products',
  components: {
    ExportPricesModal,
    ExportMultipleDaysModal,
    UploadWholesaleModal,
    UploadPromotionModal
  },
  data () {
    return {
      error: '',
      waiting: false,
      search: '',
      filter: 'all',
      classFilter: 'all',
      statusFilter: 'all',
      sortOption: { field: 'homeDepotPriceLatestChange', asc: false },
      costs: [],
      products: [],
      productMap: {},
      parentMap: {},
      exportPricesModal: {
        opened: false
      },
      exportMultipleDaysModal: {
        opened: false
      },
      uploadWhilesaleModal: {
        opened: false
      },
      uploadPromotionModal: {
        opened: false
      },
    }
  },
  computed: {
    server () {
      return this.$store.state.config.server
    },
    token () {
      return this.$store.state.user.token
    },
    filterOption () {
      return this.$store.state.products.filterOption
    },
    classFilterOption () {
      return this.$store.state.products.classFilterOption
    },
    statusFilterOption () {
      return this.$store.state.products.statusFilterOption
    },
    productClasses () {
      var classes = {}
      var count = 0
      this.products.forEach(p => {
        if (!classes[p.product.productClass]) {
          classes[p.product.productClass] = 0
        }
        classes[p.product.productClass]++
        count++
      })
      classes.all = count
      return classes
    },
    productClassesKeys () {
      var keys = Object.keys(this.productClasses)
      keys.sort()
      return keys.filter(k => k != 'all')
    },
    showingProducts () {
      var search = this.search.trim().toLowerCase()
      var filteredProducts = Object.values(this.productMap).filter(p => {
        if (p.isChild) {
          return false // children will come after parent
        }
        return p.searchAggregate.includes(search)
      })
      
      var vm = this
      var transformedProducts = filteredProducts

      if (vm.classFilter == 'all') {
        transformedProducts = transformedProducts.filter(p => p.productClass != 'competitor')
      } else {
        transformedProducts = transformedProducts.filter(p => p.productClass == vm.classFilter)
      }

      if (vm.statusFilter == 'out of stock') {
        transformedProducts = transformedProducts.filter(p => (p.homeDepotPriceFlag && p.homeDepotPriceFlag.includes('Out of Stock')) || (p.wayfairPriceFlag && p.wayfairPriceFlag.includes('Out of Stock')) || (p.overstockPriceFlag && p.overstockPriceFlag.includes('Out of Stock')) || (p.lowesPriceFlag && p.lowesPriceFlag.includes('Out of Stock')) || (p.amazonPriceFlag && p.amazonPriceFlag.includes('Out of Stock')))
      } else if (vm.statusFilter == 'price hidden') {
        transformedProducts = transformedProducts.filter(p => (p.amazonPriceFlag && p.amazonPriceFlag.includes('Price Hidden')))
      }
      
      var sort = vm.sortOption
      var sortedProducts = transformedProducts.sort((a, b) => {
        var va = a[sort.field]
        var vb = b[sort.field]
        if (va == null || vb == null) {
          if (va) {
            return sort.asc ? 1 : -1
          }
          if (vb) {
            return sort.asc ? -1 : 1
          }
          return 0
        }
        if (sort.field == 'id' || sort.field == 'homeDepotWholesalePriceValue' || sort.field == 'wayfairWholesalePriceValue' || sort.field == 'competitorPriceValue' || sort.field == 'homeDepotPriceValue' || sort.field == 'wayfairPriceValue' || sort.field == 'overstockPriceValue' || sort.field == 'amazonPriceValue' || sort.field == 'lowesPriceValue') {
          return sort.asc ? va - vb : vb - va
        }
        return sort.asc ? va.localeCompare(vb) : vb.localeCompare(va)
      })

      for (var i=sortedProducts.length-1;i>=0;i--) {
        if (sortedProducts[i].isParent) {
          var children = vm.parentMap[sortedProducts[i].id].childIds.map(id => vm.productMap[id])
          for (var j=children.length-1;j>=0;j--) {
            sortedProducts.splice(i+1, 0, children[j])
          }
        }
      }

      return sortedProducts
    },
  },
  watch: {
    filter: function (val) {
      this.$store.commit('products/setFilterOption', val)
    },
    classFilter: function (val) {
      this.$store.commit('products/setClassFilterOption', val)
    },
    statusFilter: function (val) {
      this.$store.commit('products/setStatusFilterOption', val)
    },
  },
  methods: {
    getProducts () {
      this.waiting = true
      var vm = this
      this.$http.get(this.server + '/myapp/get-products-with-latest-prices/').then(resp => {
        var products = resp.body
        var competitorMap = this.makeCompetitorMap(products)
        var productMap = {}
        var parentMap = {}
        for (var p of products) {
          var transformedProduct = vm.transformProduct(p, competitorMap)
          if (transformedProduct.product.childIds) {
            var childIds = transformedProduct.product.childIds.split(/,|;/).filter(c => c)
            if (childIds.length) {
              parentMap[p.product.id] = {
                opened: false,
                childIds: childIds
              }
            }
          }
          productMap[p.product.id] = transformedProduct
        }
        for (var parentId in parentMap) {
          var parent = productMap[parentId]
          var children = parentMap[parentId].childIds.map(id => productMap[id])
          vm.makeUpParentAndChildren(parent, children)
        }
        this.products = products
        this.parentMap = parentMap
        this.productMap = productMap
        this.waiting = false
      }, err => {
        this.error = err.body
        this.waiting = false
      })
    },
    makeUpParentAndChildren(parent, children) {
      parent.isParent = true
      parent.nsInventoryAmount = null
      parent.nsCaInventoryAmount = null
      parent.nsNyInventoryAmount = null
      parent.nsTxInventoryAmount = null
      parent.competitorPriceValue = null
      parent.competitorPriceLabel = ''
      parent.competitorPriceDate = ''
      parent.competitorPriceBrand = ''
      for (var child of children) {
        child.isChild = true
        child.parentId = parent.id
        parent.searchAggregate += (', ' + child.searchAggregate)
        if (child.nsInventoryAmount) {
          if (parent.nsInventoryAmount == null || parent.nsInventoryAmount < child.nsInventoryAmount) {
            parent.nsInventoryAmount = child.nsInventoryAmount
            parent.nsCaInventoryAmount = child.nsCaInventoryAmount
            parent.nsNyInventoryAmount = child.nsNyInventoryAmount
            parent.nsTxInventoryAmount = child.nsTxInventoryAmount
          }
        }
        if (child.competitorPriceValue) {
          if (!parent.competitorPriceValue || parent.competitorPriceValue > child.competitorPriceValue) {
            parent.competitorPriceValue = child.competitorPriceValue
            parent.competitorPriceLabel = child.competitorPriceLabel
            parent.competitorPriceDate = child.competitorPriceDate
            parent.competitorPriceBrand = child.competitorPriceBrand
          }
        }
      }
      for (var name of ['homeDepot', 'wayfair', 'overstock', 'amazon', 'lowes']) {
        this.makeUpParentChannelPrice(parent, children, name)
      }
    },
    makeUpParentChannelPrice(parent, children, name) {
      var minDate = ''
      var minPrice = ''
      var maxPrice = ''
      var count = 0
      var sum = 0
      var flag = ''
      for (var child of children) {
        if (child[name + 'PriceValue']) {
          if (!minDate || minDate > child[name + 'PriceDate']) {
            minDate = child[name + 'PriceDate']
          }
          if (!minPrice || minPrice > child[name + 'PriceValue']) {
            minPrice = child[name + 'PriceValue']
          }
          if (!maxPrice || maxPrice < child[name + 'PriceValue']) {
            maxPrice = child[name + 'PriceValue']
          }
          count ++;
          sum += child[name + 'PriceValue']
          flag += (child[name + 'PriceFlag'] + ', ')
        }
      }
      var average = ''
      if (count) {
        average = sum / count
      }
      parent[name + 'PriceValue'] = average
      parent[name + 'PriceLabel'] = minPrice == maxPrice ? formatPrice(minPrice) : (formatPrice(minPrice) + ' ~ ' + formatPrice(maxPrice))
      parent[name + 'PriceDate'] = minDate
      parent[name + 'PriceFlag'] = flag
      parent[name + 'PriceLatestChange'] = ''
      parent[name + 'PriceLatestChangeLabels'] = ''
      parent[name + 'PriceLatestChangePriceLabel'] = ''
      this.makeUpParentChannelPriceLatestChange(parent, children, name)
    },
    makeUpParentChannelPriceLatestChange(parent, children, name) {
      var maxDate = ''
      var minPrice = null
      var maxPrice = null
      for (var child of children) {
        if (child[name + 'PriceLatestChange']) {
          var ss = child[name + 'PriceLatestChange'].split(',')
          var date = ss[0]
          if (!maxDate || date > maxDate) {
            maxDate = date
          }
          var price = parseFloat(ss[2])
          if (!maxPrice || price > maxPrice) {
            maxPrice = price
          }
          if (!minPrice || price < minPrice) {
            minPrice = price
          }
        }
      }
      parent[name + 'PriceLatestChange'] = maxDate
      parent[name + 'PriceLatestChangeLabels'] = [maxDate]
      if (minPrice) {
        if (minPrice == maxPrice) {
          parent[name + 'PriceLatestChangePriceLabel'] = formatPrice(minPrice)
        } else {
          parent[name + 'PriceLatestChangePriceLabel'] = formatPrice(minPrice) + ' ~ ' + formatPrice(maxPrice)
        }
      }
    },
    makeCompetitorMap (products) {
      var competitorMap = {}
      products.forEach(p => {
        if (p.product.productClass == 'competitor') {
          var latestMinPrice = null
          for (var c of p.channels) {
            if (c.latestPrice) {
              if (latestMinPrice != null) {
                if (latestMinPrice.value > c.latestPrice.price) {
                  latestMinPrice = {value: c.latestPrice.price, date: c.latestPrice.date, brand: p.product.brand}
                }
              } else {
                latestMinPrice = {value: c.latestPrice.price, date: c.latestPrice.date, brand: p.product.brand}
              }
            }
          }
          if (latestMinPrice) {
            competitorMap[p.product.id] = latestMinPrice
          }
        }
      })
      return competitorMap
    },
    makeSearchAggregate (p) {
      var values = [
        p.product.name,
        p.product.model,
        p.product.productClass,
        p.product.nsSku,
        p.product.nsIdIndex,
        p.product.homeDepotItemId,
        p.product.wayfairSku,
        p.product.overstockSku,
        p.product.amazonAsin,
        p.product.lowesItemId,
      ]
      return values.filter(v => v).map(v => v.toLowerCase()).join(', ')
    },
    transformProduct (p, competitorMap) {
      var vm = this
      var homeDepotPrice = vm.findChannelPrice(p, 'Home Depot')
      var wayfairPrice = vm.findChannelPrice(p, 'Wayfair')
      var overstockPrice = vm.findChannelPrice(p, 'Overstock')
      var amazonPrice = vm.findChannelPrice(p, 'Amazon')
      var lowesPrice = vm.findChannelPrice(p, 'Lowes')
      var competitorPrice = vm.findCompetitorPrice(p, competitorMap)
      var nsInventory = vm.findNsInventory([homeDepotPrice, wayfairPrice, overstockPrice, amazonPrice, lowesPrice], 'nsInventoryAmount')
      var nsCaInventory = vm.findNsInventory([homeDepotPrice, wayfairPrice, overstockPrice, amazonPrice, lowesPrice], 'nsCaInventoryAmount')
      var nsNyInventory = vm.findNsInventory([homeDepotPrice, wayfairPrice, overstockPrice,amazonPrice, lowesPrice], 'nsNyInventoryAmount')
      var nsTxInventory = vm.findNsInventory([homeDepotPrice, wayfairPrice, overstockPrice, amazonPrice, lowesPrice], 'nsTxInventoryAmount')

      var wayfairWholesalePriceLabel = ''
      var wayfairWholesalePriceValue = 0
      var wayfairPromotionPercentageLabel = ''
      var wayfairPromotionPercentageValue = null
      if (p.product.wayfairData) {
        var wayfairData = JSON.parse(p.product.wayfairData)
        var wayfairWholesalePrice = wayfairData.wayfairWholesalePrice
        if (wayfairWholesalePrice) {
          wayfairWholesalePriceValue = wayfairWholesalePrice.price
          wayfairWholesalePriceLabel = formatPrice(wayfairWholesalePriceValue)
        }
        if (wayfairWholesalePriceValue && wayfairData.wayfairPromotionPercentage && wayfairData.wayfairPromotionPercentage.percentage) {
          var wayfairPromotion = wayfairData.wayfairPromotionPercentage
          wayfairPromotionPercentageValue = wayfairPromotion.percentage
          wayfairPromotionPercentageLabel = formatPrice(wayfairWholesalePriceValue * (1 - (wayfairPromotionPercentageValue / 100)))
        }
      }

      var homeDepotWholesalePriceLabel = ''
      var homeDepotWholesalePriceValue = 0
      var homeDepotPromotionPercentageLabel = ''
      var homeDepotPromotionPercentageValue = null
      if (p.product.homeDepotData) {
        var homeDepotData = JSON.parse(p.product.homeDepotData)
        var homeDepotWholesalePrice = homeDepotData.homeDepotWholesalePrice
        if (homeDepotWholesalePrice) {
          homeDepotWholesalePriceValue = homeDepotWholesalePrice.price
          homeDepotWholesalePriceLabel = formatPrice(homeDepotWholesalePriceValue)
        }
        if (homeDepotWholesalePriceValue && homeDepotData.homeDepotPromotionPercentage && homeDepotData.homeDepotPromotionPercentage.percentage) {
          var homeDepotPromotion = homeDepotData.homeDepotPromotionPercentage
          homeDepotPromotionPercentageValue = homeDepotPromotion.percentage
          homeDepotPromotionPercentageLabel = formatPrice(homeDepotWholesalePriceValue * (1 - (homeDepotPromotionPercentageValue / 100)))
        }
      }

      var result = {
        product: p.product,
        searchAggregate: vm.makeSearchAggregate(p),
        id: p.product.id,
        name: p.product.name,
        nameAbbr: p.product.name + ', ' + p.product.homeDepotItemId + ', ' + p.product.wayfairSku + ', ' + p.product.overstockSku,
        model: p.product.model,
        productClass: p.product.productClass,
        brand: p.product.brand,
        nsSku: p.product.nsSku,
        nsIdIndex: p.product.nsIdIndex,
        nsInventoryAmount: nsInventory,
        nsCaInventoryAmount: nsCaInventory,
        nsNyInventoryAmount: nsNyInventory,
        nsTxInventoryAmount: nsTxInventory,
        competitorPriceValue: competitorPrice.value,
        competitorPriceLabel: competitorPrice.label,
        competitorPriceDate: competitorPrice.date,
        competitorPriceBrand: competitorPrice.brand,
        wayfairWholesalePriceValue: wayfairWholesalePriceValue,
        wayfairWholesalePriceLabel: wayfairWholesalePriceLabel,
        wayfairPromotionPercentageLabel: wayfairPromotionPercentageLabel,
        homeDepotWholesalePriceValue: homeDepotWholesalePriceValue,
        homeDepotWholesalePriceLabel: homeDepotWholesalePriceLabel,
        homeDepotPromotionPercentageLabel: homeDepotPromotionPercentageLabel,
      }

      var channelPrices = {
        homeDepot: homeDepotPrice,
        wayfair: wayfairPrice,
        overstock: overstockPrice,
        amazon: amazonPrice,
        lowes: lowesPrice
      }
      for (var name in channelPrices) {
        var price = channelPrices[name]
        result[name + 'PriceValue'] = price.value
        result[name + 'PriceLabel'] = price.label
        result[name + 'PriceDate'] = price.date
        result[name + 'PriceFlag'] = price.flag
        result[name + 'PriceLatestChange'] = price.latestChange
        result[name + 'PriceLatestChangeLabels'] = price.latestChangeLabels
        result[name + 'PriceLatestChangePriceLabel'] = (price.latestChangeLabels && price.latestChangeLabels[2]) ? formatPrice(price.latestChangeLabels[2]) : ''
      }

      return result
    },
    findChannelPrice (product, channelName) {
      var homeDepotChannel = product.channels.find(c => {
         return c.channel.name == channelName
      })
      if (!homeDepotChannel || !homeDepotChannel.latestPrice) {
        return {}
      }
      var price = homeDepotChannel.latestPrice
      return {
        value: price.price,
        label: formatPrice(price.price),
        date: price.date,
        flag: price.flag,
        latestChange: price.latestChange,
        latestChangeLabels: price.latestChange ? price.latestChange.split(',') : [],
        nsInventoryAmount: price.nsInventoryAmount,
        nsCaInventoryAmount: price.nsCaInventoryAmount,
        nsNyInventoryAmount: price.nsNyInventoryAmount,
        nsTxInventoryAmount: price.nsTxInventoryAmount,
      }
    },
    findCompetitorPrice (product, competitorMap) {
      var competitorIds = product.product.competitorIds.split(/,|;/).filter(i => i.trim())
      if (!competitorIds.length) {
        return {value: 0, label: '', date: null, brand: null}
      }
      var minPrice = null
      for (var competitorId of competitorIds) {
        var price = competitorMap[competitorId.trim()]
        if (price) {
          if (minPrice) {
            if (minPrice.value > price.value) {
              minPrice = price
            }
          } else {
            minPrice = price
          }
        }
      }
      if (!minPrice) {
        return {value: 0, label: '', date: null}
      }
      return {
        value: minPrice.value,
        label: formatPrice(minPrice.value),
        date: minPrice.date,
        brand: minPrice.brand,
      }
    },
    findNsInventory (prices, name) {
      for (var price of prices) {
        if (price[name] != undefined && price[name] != null) {
          return price[name]
        }
      }
    },
    changeSortOption (field) {
      this.sortOption = {
        field: field,
        asc: field == this.sortOption.field ? !this.sortOption.asc : true
      }
    },
    viewProduct (product) {
      this.$router.push('/product/' + product.id)
    },
    exportThisPage () {
      var headers = ['id', 'model', 'productClass', 'brand', 'nsSku', 'nsIdIndex',
        'nsInventoryAmount', 'nsCaInventoryAmount', 'nsNyInventoryAmount', 'nsTxInventoryAmount',
        'homeDepotPriceValue', 'homeDepotPriceLatestChange', 'homeDepotPriceFlag',
        'wayfairPriceValue', 'wayfairPriceLatestChange', 'wayfairPriceFlag',
        'overstockPriceValue', 'overstockPriceLatestChange', 'overstockPriceFlag',
        'amazonPriceValue', 'amazonPriceLatestChange', 'amazonPriceFlag',
        'lowesPriceValue', 'lowesPriceLatestChange', 'lowesPriceFlag',
      ]
      var rows = this.showingProducts.map(p => headers.map (h => (p[h] ? p[h] : '')).join('\t'))
      rows.unshift(headers.join('\t'))
      var string = rows.join('\r\n')
      var blob = new Blob([string], {type: 'text/plain;charset=utf-8'})
      saveAs(blob, 'product_prices.tsv')
    },
    uploadWhilesaleModalClosed () {
      this.uploadWhilesaleModal.opened = false
      this.getProducts()
    },
    uploadPromotionModalClosed () {
      this.uploadPromotionModal.opened = false
      this.getProducts()
    },
  },
  mounted () {
    this.getProducts()
    this.filter = this.filterOption
    this.classFilter = this.classFilterOption
    this.statusFilter = this.statusFilterOption
  },
}
</script>

<style scoped>
  .price-cell {
    min-width: 100px;
  }
  .price-change-cell {
    min-width: 110px;
  }

  .is-parent {
    background-color: hsl(219, 70%, 96%);
    font-weight: bold;
    color: #4A306D;
  }
  .is-child {
    background-color: hsl(206, 70%, 96%);
  }
</style>
