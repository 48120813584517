<template>
  <div class="modal" :class="{'is-active': opened}">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Export Prices</p>
        <button class="delete" @click="close"></button>
      </header>
      <section class="modal-card-body">
        <div class="field">
          <label class="label">Select Date</label>
          <div class="control">
            <datepicker v-model="selectedDate" :inline="true" name="uniquename"></datepicker>
          </div>
        </div>
        <div v-if="error" class="notification is-danger is-light">
          <button class="delete" @click="error=''"></button>
          {{error}}
        </div>
      </section>
      <footer class="modal-card-foot">
        <a class="button is-link" :class="{'is-loading': waiting}" :disabled="!selectedDate" @click="exportPrices">Export</a>
        <a class="button" @click="close">Cancel</a>
      </footer>
    </div>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker'
import dateFormat from 'dateformat'
import { saveAs } from 'file-saver'

export default {
  name: 'export-prices-modal',
  components: {
    Datepicker
  },
  props: ['opened'],
  data () {
    return {
      error: '',
      waiting: false,
      selectedDate: null,
    }
  },
  computed: {
    server () {
      return this.$store.state.config.server
    },
  },
  methods: {
    close () {
      this.$emit('closeExportPricesModal')
    },
    exportPrices () {
      if (!this.selectedDate) {
        return
      }
      var date = dateFormat(this.selectedDate, 'isoDate')
      this.waiting = true
      this.$http.get(this.server + '/myapp/get-product-prices-for-date/' + date + '/').then(resp => {
        this.processData(resp.body, date)
        this.waiting = false
      }, err => {
        this.error = err.body
        this.waiting = false
      })
    },
    processData (data, date) {
      var vm = this
      var header = ['id', 'model', 'Class', 'NS Sku', 'NS Id Index', 'NS inventory', 'NS CA', 'NS NY', 'NS TX', 'Home Depot Price', 'Wayfair Price', 'Overstock Price', 'Amazon Price', 'Lowes Price', 'name']
      var rows = []
      for (const p of data) {
        var row = []
        row.push(p.product.id.toString())
        row.push(p.product.model)
        row.push(p.product.productClass)
        row.push(p.product.nsSku)
        row.push(p.product.nsIdIndex)
        var homeDepotPrice = ''
        var wayfairPrice = ''
        var overstockPrice = ''
        var amazonPrice = ''
        var lowesPrice = ''
        var nsInventory = ''
        var nsCaInventory = ''
        var nsNyInventory = ''
        var nsTxInventory = ''
        for (const c of p.channels) {
          nsInventory = nsInventory || this.getNsInventory(c.price, 'nsInventoryAmount')
          nsCaInventory = nsCaInventory || this.getNsInventory(c.price, 'nsCaInventoryAmount')
          nsNyInventory = nsNyInventory || this.getNsInventory(c.price, 'nsNyInventoryAmount')
          nsTxInventory = nsTxInventory || this.getNsInventory(c.price, 'nsTxInventoryAmount')
          if (c.channel.name == 'Home Depot' && c.price && c.price.price) {
            homeDepotPrice = c.price.price.toString()
          }
          if (c.channel.name == 'Wayfair' && c.price && c.price.price) {
            wayfairPrice = c.price.price.toString()
          }
          if (c.channel.name == 'Overstock' && c.price && c.price.price) {
            overstockPrice = c.price.price.toString()
          }
          if (c.channel.name == 'Amazon' && c.price && c.price.price) {
            amazonPrice = c.price.price.toString()
          }
          if (c.channel.name == 'Lowes' && c.price && c.price.price) {
            lowesPrice = c.price.price.toString()
          }
        }
        row.push(nsInventory)
        row.push(nsCaInventory)
        row.push(nsNyInventory)
        row.push(nsTxInventory)
        row.push(homeDepotPrice)
        row.push(wayfairPrice)
        row.push(overstockPrice)
        row.push(amazonPrice)
        row.push(lowesPrice)
        row.push(p.product.name)
        rows.push(row.join('\t'))
      }
      rows.unshift(header.join('\t'))
      var string = rows.join('\r\n')
      var blob = new Blob([string], {type: 'text/plain;charset=utf-8'})
      saveAs(blob, date + '.tsv')
    },
    getNsInventory (price, name) {
      if (price && price[name] != null && price[name] != undefined) {
        return price[name].toString()
      }
      return ''
    }
  },
}
</script>

<style scoped>
.my-datepicker {
  height: 400px;
}
</style>